<form #searchForm="ngForm">
<div [ngClass]="gridLayout? gridLayout : 'filter'">
    <div *ngFor="let searchField of comboFieldSearchArray" [ngClass]="gridLayout? '' : 'search-section filter-option'"  [class.div-break]="searchField['break']">
        <div *ngIf="searchField['type'] === 'select'" [ngClass]="gridLayout? 'grid-item' : '' ">
            <mat-form-field hideRequiredMarker [ngClass]="gridLayout? '' : 'form-select'">
                    <mat-label class="label">{{searchField['label'] | translate}}
                        <span *ngIf="searchField['required'] && !searchField['disabled']" class="mandatory">*</span>
                    </mat-label>
                <mat-select   *ngIf="!searchField['autocomplete']"  [required]="searchField['required']  && !searchField['disabled']" [name]="searchField['name']" [disabled]="searchField['disabled']" [class.disabled]="searchField['disabled']"
                    [placeholder]="searchField['placeholder'] | translate" [(ngModel)]="fields[searchField['name']]"
                    (selectionChange)="selectValue($event,searchField,searchField['childField'])" class="select-arrow input-field">
                    <mat-option *ngIf="!searchField['noSelect']">{{ 'SELECT' | translate }}</mat-option>
                    <mat-option *ngFor="let val of dropDownValues[searchField['name']]" [value]="val.id">
                        {{val['name'] | translate}}</mat-option>
                </mat-select>
                  <input *ngIf="searchField['autocomplete']"
                  matInput
                  [matAutocomplete]="auto"
                  [placeholder]="(searchField['placeholder']| translate ) + ' ' + (searchField['label'] | translate)"
                  [(ngModel)]="fields[searchField['name']]"
                  [name]="searchField['name']"
                  [disabled]="searchField['disabled']"
                  [required]="searchField['required'] && !searchField['disabled']"
                  (input)="onAutocompleteInput($event, searchField)"
                  (focus)="onAutocompleteFocus(searchField)">
           <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onAutocompleteSelect($event, searchField, searchField['childField'])"
           [displayWith]="displayFn.bind(this, searchField)">
             <mat-option *ngFor="let val of filteredOptions[searchField['name']]" [value]="val.id">
               {{ val['name'] | translate }}
             </mat-option>
           </mat-autocomplete>
            </mat-form-field>
            <div class="error-message" *ngIf="submitted || searchForm.form.get(searchField['name'])?.touched && searchForm.form.get(searchField['name'])?.errors">
                <div *ngIf="searchForm.form.get(searchField['name'])?.errors?.required">
                    <mat-error>
                        {{searchField['label'] | translate}} {{'IS_REQUIRED' | translate }}
                    </mat-error>
                </div>
                </div>
        </div>

        <div *ngIf="searchField['type'] === 'multi-select'" [ngClass]="gridLayout? 'grid-item' : '' ">
            <mat-form-field hideRequiredMarker [ngClass]="gridLayout? '' : 'form-select'">
                    <mat-label class="label">{{searchField['label'] | translate}}
                        <span *ngIf="searchField['required']  && !searchField['disabled']" class="mandatory">*</span>
                    </mat-label>
                <mat-select [required]="searchField['required']  && !searchField['disabled']" [name]="searchField['name']" [disabled]="searchField['disabled']" [class.disabled]="searchField['disabled']"
                    [placeholder]="searchField['placeholder'] | translate" [(ngModel)]="fields[searchField['name']]"
                    (selectionChange)="selectValue($event,searchField,searchField['childField'])" multiple
                    class="select-arrow input-field">
                    <mat-option *ngFor="let val of dropDownValues[searchField['name']]" [value]="val.id" [title]="searchField['title'] ? val['name'] : ''">
                        {{val['name']}}</mat-option>
                </mat-select>
            </mat-form-field>
            <div class="error-message" *ngIf="submitted  || searchForm.form.get(searchField['name'])?.touched && searchForm.form.get(searchField['name'])?.errors">
                <div *ngIf="searchForm.controls[searchField['name']]?.errors?.required">
                    <mat-error>
                        {{searchField['label'] | translate}} {{'IS_REQUIRED' | translate }}
                    </mat-error>
                </div>
            </div>
        </div>

        <div *ngIf="searchField['type'] === 'date'" [ngClass]="gridLayout? 'grid-item' : '' ">
            <mat-form-field hideRequiredMarker [ngClass]="gridLayout? '' : 'form-select'">
                    <mat-label class="label">{{searchField['label'] | translate}}
                        <span *ngIf="searchField['required'] && !searchField['disabled']" class="mandatory">*</span>
                    </mat-label>
                <input [required]="searchField['required']  && !searchField['disabled']" [name]="searchField['name']" [class.disabled]="searchField['disabled']" matInput [matDatepicker]="picker" [min]="minDate" [max]="maxDate"
                    [placeholder]="searchField['placeholder'] | translate" [(ngModel)]="fields[searchField['name']]"
                    (ngModelChange)="dateSelected()" readonly [disabled]="searchField['disabled']" class="date-input-color input-field">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker [disabled]="searchField['disabled']"></mat-datepicker>
            </mat-form-field>
            <div class="error-message" *ngIf="submitted  || searchForm.form.get(searchField['name'])?.touched && searchForm.form.get(searchField['name'])?.errors">
                <div *ngIf="searchForm.controls[searchField['name']]?.errors?.required">
                    <mat-error>
                        {{searchField['label'] | translate}} {{'IS_REQUIRED' | translate }}
                    </mat-error>
                </div>
                <mat-error *ngIf="searchField['validate'] && searchForm.controls[searchField['name']]?.errors?.invalidDate">
                    {{'FROMDATE_LESSTHAN_TODATE' | translate }}</mat-error>
                </div>
        </div>

        <div *ngIf="searchField['type'] === 'monthPicker'" [ngClass]="gridLayout? 'grid-item' : '' ">
            <mat-form-field hideRequiredMarker [ngClass]="gridLayout? '' : 'form-date'">
                    <mat-label class="label">{{searchField['label'] | translate}}
                        <span *ngIf="searchField['required']   && !searchField['disabled']" class="mandatory">*</span>
                    </mat-label>
                <!-- to display date selected in MM-yyyy format -->
                <input [name]="searchField['name']" [required]="searchField['required']  && !searchField['disabled']" [(ngModel)]="fields[searchField['name']]" [placeholder]="searchField['placeholder'] | translate"
                readonly [disabled]="searchField['disabled']" class="date-input-color input-field" (ngModelChange)="dateSelected()"/>
                <input [class.disabled]="searchField['disabled']" matInput [matDatepicker]="picker" [max]="maxDate" hidden 
                    class="date-input-color">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker [disabled]="searchField['disabled']" startView="multi-year" 
                    (monthSelected)="chosenMonthHandler($event, picker, searchField['name'])"></mat-datepicker>
            </mat-form-field>
            <div class="error-message" *ngIf="submitted  || searchForm.form.get(searchField['name'])?.touched && searchForm.form.get(searchField['name'])?.errors">
                <div *ngIf="searchForm.controls[searchField['name']]?.errors?.required">
                    <mat-error>
                        {{searchField['label'] | translate}} {{'IS_REQUIRED' | translate }}
                    </mat-error>
                </div>
                </div>
                <div class="error-message" *ngIf="searchField['validate'] && searchForm.controls[searchField['name']]?.errors?.invalidDate">
                    <mat-error >
                        {{'FROMDATE_LESSTHAN_TODATE' | translate }}</mat-error>
                </div>
        </div>

        <div *ngIf="searchField['type'] === 'text'" [ngClass]="gridLayout? 'grid-item' : '' ">
            <mat-form-field hideRequiredMarker [ngClass]="gridLayout? '' : 'form-text'">
                    <mat-label class="label">{{searchField['label'] | translate}}
                        <span *ngIf="searchField['required'] && !searchField['disabled']" class="mandatory">*</span>
                    </mat-label>
                    <span *ngIf="searchField['infoIcon']" class="info-icon">
                        <img [src]="searchField['infoIcon']" alt="info" [title]="searchField['tooltip'] | translate">
                    </span>
                <input [required]="searchField['required'] && !searchField['disabled']" [pattern]="searchField['pattern']" [name]="searchField['name']" [disabled]="searchField['disabled']" [class.disabled]="searchField['disabled']"
                    [type]="searchField['type']" matInput [(ngModel)]="fields[searchField['name']]"
                    [placeholder]="searchField['placeholder'] | translate" class="search-input input-field" />
            </mat-form-field>
            <div class="error-message" *ngIf="submitted  || searchForm.form.get(searchField['name'])?.touched && searchForm.form.get(searchField['name'])?.errors">
            <div *ngIf="searchForm.controls[searchField['name']]?.errors?.required">
                <mat-error>
                    {{searchField['label'] | translate}} {{'IS_REQUIRED' | translate }}
                </mat-error>
            </div>
            <mat-error *ngIf="searchForm.controls[searchField['name']]?.errors?.pattern">
                {{searchField['errorPatternMsg'] | translate}}</mat-error>
            </div>
        </div>

        <div *ngIf="searchField['type'] === 'button'" [class.disabledButton]="searchField['disabled']" class="input-field" [ngClass]="gridLayout? 'grid-item' : '' ">
            <div [class]="searchField['class']" (click)="onSearchClick()" [title]="'SEARCH' | translate">
                <img [src]="searchField['source']" alt="search">
            </div>
        </div>
         <div *ngIf="searchField['type'] === 'getButton'"  class="input-field-get" [ngClass]="gridLayout? 'grid-item' : '' ">
            <button  (click)="onSearchClick()" [ngClass]="searchField['class']" mat-raised-button class="button save-continue-button button-submit">{{searchField['buttonName'] | translate }}</button>
        </div>  
        <div *ngIf="searchField['type'] === 'searchButton'"  class="input-field-get" [ngClass]="gridLayout? 'grid-item' : '' ">
            <button  (click)="onSearchClick()" mat-raised-button class="button save-continue-button button-submit">{{'SEARCH' | translate }}</button>
        </div>  
    </div>
</div>
</form>
<section class="section" *ngIf="displaySubmitButton">
    <button (click)="onSearchClick()" mat-raised-button class="button save-continue-button button-submit">
      {{ 'SUBMIT' | translate }}</button>
    <button  (click)="onReset()" mat-button class="button">{{'RESET' | translate }}</button>
</section>