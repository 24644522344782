<section class="overview-container">
  <section class="main">
    <div class="header-style">
      <span class="heading-label">
        <img class="page-icon" [src]="billingIcon" />
        <span>{{'BILLING_DATA_MANAGEMENT' |translate}}</span>
      </span>
    </div>
    <div class="panel-body">
      <app-search-component *ngIf="formData" [comboFieldSearchArray]="formData" [dropDownValues]="dropDownValues"
            (valueSelected)="updateDropdownValues($event)" (searchFieldEmitter)="generateBillingInvoice($event)">
      </app-search-component>
    </div>
    <div class="clear-float"></div>
    <div *ngIf="billingData && billingData.length" class="table-container">
      <mat-table [dataSource]="billingDataSource" class="bottom-space table-data">
        <div *ngFor="let column of columns">
          <ng-container [matColumnDef]="column">
            <mat-header-cell *matHeaderCellDef [ngClass]="column">{{column | appendSpace | titlecase | translate }}
            </mat-header-cell>
            <mat-cell class="tooltip mat-cell" *matCellDef="let dataName" [ngClass]="column">
              <span *ngIf="column !== 'invoiceUrl'" title="{{dataName[column]}}" class="cell-value">
                {{ dataName[column] }}
              </span>
              <span *ngIf="column === 'invoiceUrl'">
                <button *ngIf="dataName['invoiceUrl']">
                  <a href="{{dataName['invoiceUrl']}}" target="_blank">
                    <img [src]="downloadIcon" [title]="'DOWNLOAD_FILE' | translate" /></a>
                </button>
              </span>
            </mat-cell>
          </ng-container>
        </div>
        <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
        <mat-row *matRowDef="let data; columns: columns;"></mat-row>
      </mat-table>
    </div>
  </section>
</section>