<div [ngClass]="columnsInForm?columnsInForm:''">
    <form *ngIf="formBase" [formGroup]="createForm" class="dynamic-form" [ngClass]="gridLayout?gridLayout:''">
        <div *ngFor="let arr of formBase" class="dynamic-form-div" [ngClass]="{ 'display-none': arr['displayNone'],'reduce-opacity': arr['reduceOpacity']}">
            <div class="form-group text" [ngClass]="{'grid-item': gridLayout,'disable-pointer-event': arr['disableEvent']}"
                *ngIf="arr['type'] === 'text' || arr['type'] === 'tel' || arr['type'] === 'password' || arr['type'] === 'number'">
                <mat-form-field class="dynamic-form-text-fields">
                    <mat-label class="label">{{arr['label'] | translate}}
                        <span *ngIf="arr['required']" class="mandatory">*</span>
                    </mat-label>
                    <input [tabIndex]="arr['disableEvent']?-1:0" [type]="arr['type']" (blur)="textInputonblur()" (focus)="textInputonfocus()" matInput [formControlName]="arr['name']" [readonly]="(arr['readOnly'])"
                        [min]="arr['min']" [max]="arr['max']" [placeholder]="arr['placeholder'] | translate"
                        class="dynamic-input" />
                    <div matSuffix *ngIf="arr['infoIcon']" class="info-icon">
                        <img [src]="arr['infoIcon']" alt="info" [title]="arr['tooltip'] | translate">
                    </div>
                    <span matSuffix *ngIf="arr['unit']">{{arr['unit'] | translate}}</span>
                </mat-form-field>
                <span class='info-link-div' *ngIf="arr['link']">
                    <mat-label *ngIf="arr['link']" class="link-label">{{ 'RULES_LINK' | translate }}</mat-label>
                    <a *ngIf="arr['link']" [href]="arr['link']" target="_blank"
                        class="reference-link mat-form-field">{{arr['link'] | translate }}</a>
                </span>
                <div
                    *ngIf="submitted || createForm.controls[arr['name']].touched && createForm.controls[arr['name']].errors">
                    <div *ngIf="createForm.controls[arr['name']]?.errors?.required">
                        <mat-error>
                            {{arr['label'] | translate}} {{'IS_REQUIRED' | translate }}
                        </mat-error>
                    </div>
                    <mat-error *ngIf="createForm.controls[arr['name']]?.errors?.minlength">
                        {{arr['errorMinLengthMsg'] | translate}}</mat-error>
                    <mat-error *ngIf="createForm.controls[arr['name']]?.errors?.maxlength">
                        {{arr['errorMaxLengthMsg'] | translate}}</mat-error>
                    <mat-error *ngIf="createForm.controls[arr['name']]?.errors?.pattern">
                        {{arr['errorPatternMsg'] | translate}}</mat-error>
                    <mat-error *ngIf="createForm.controls[arr['name']]?.errors?.min">
                        {{arr['errorMinMsg'] | translate}}</mat-error>
                    <mat-error *ngIf="createForm.controls[arr['name']]?.errors?.max">
                        {{arr['errorMaxMsg'] | translate}}</mat-error>
                        <mat-error *ngIf="createForm.controls[arr['name']]?.errors?.duplicate">
                            {{arr['errorDuplicateMsg'] | translate}}</mat-error>
                </div>
            </div>

            <div class="form-group" *ngIf="arr['type'] === 'textArea'" [ngClass]="[gridLayout ? 'grid-item' : '',  arr['disableEvent'] ? 'disable-pointer-event' : '', arr['class']?arr['class']:'']">
                <mat-form-field floatLabel="always" class="dynamic-form-text-area">
                    <mat-label class="label">{{arr['label'] | translate}}
                        <span *ngIf="arr['required']" class="mandatory">*</span>
                    </mat-label>
                    <textarea [tabIndex]="arr['disableEvent']?-1:0"class="form-control text-area dynamic-input" matInput [formControlName]="arr['name']"
                        [placeholder]="arr['placeholder'] | translate" rows="4">
                    </textarea>
                    <button class="text-button" mat-raised-button *ngIf="arr['button']"
                        (click)="textButtonClick(arr['name'])">{{arr['buttonLabel'] | translate}}</button>
                    <span matSuffix *ngIf="arr['unit']">{{arr['unit'] | translate}}</span>
                </mat-form-field>

                <div
                    *ngIf="submitted || createForm.controls[arr['name']].touched && createForm.controls[arr['name']].errors">
                    <div *ngIf="createForm.controls[arr['name']]?.errors?.required">
                        <mat-error class="mat-error-class">
                            {{arr['label'] | translate}} {{'IS_REQUIRED' | translate }}
                        </mat-error>
                    </div>
                    <mat-error *ngIf="createForm.controls[arr['name']]?.errors?.minlength" class="mat-error-class">
                        {{arr['errorMinLengthMsg'] | translate}}</mat-error>
                    <mat-error *ngIf="createForm.controls[arr['name']]?.errors?.maxlength" class="mat-error-class">
                        {{arr['errorMaxLengthMsg'] | translate}}</mat-error>
                    <mat-error *ngIf="createForm.controls[arr['name']]?.errors?.pattern" class="mat-error-class">
                        {{arr['errorPatternMsg'] | translate}}</mat-error>
                </div>
            </div>

            <div class="form-group" *ngIf="arr['type'] === 'radio'">
                <div>
                    <mat-label class="label-subHeader">{{arr['label'] | translate}}</mat-label> 
                </div>
                <div class="radio-button">
                    <mat-radio-group [formControlName]="arr['name']" [(ngModel)]="arr['value']" (change)="(arr['isParent'] && selectNewField($event, arr))">
                        <mat-radio-button *ngFor="let option of arr['values']" [value]="option.value">
                        {{ option.name | translate }}
                        </mat-radio-button>
                    </mat-radio-group>
                </div>
                <div *ngIf="submitted || control[arr['name']].touched && control[arr['name']].errors">
                    <div *ngIf="control[arr['name']]?.errors?.required">{{arr['label'] | translate}}
                        {{'IS_REQUIRED' | translate }}</div>
                </div>
            </div>
            
            <div class="form-group form-check master-checkbox" *ngIf="arr.type === 'checkbox'" [ngClass]="gridLayout?'grid-item':''">
                <mat-label class="label">{{arr['label'] | translate}}
                    <span *ngIf="arr['required']" class="mandatory">*</span>
                </mat-label>
                <mat-checkbox class="dynamic-input-checkbox" [formControlName]="arr['name']" [(ngModel)]="arr['value']"
                    (change)="(arr['isParent'] && selectNewField($event, arr))">
                </mat-checkbox>
                <span *ngIf="arr['value'] == true && arr['message']" class="label">
                    {{arr['message'] | translate}}
                </span>
                <div *ngIf="submitted || control[arr['name']].touched && control[arr['name']].errors">
                    <div *ngIf="control[arr['name']]?.errors?.required">{{arr['label'] | translate}}
                        {{'IS_REQUIRED' | translate }}</div>
                </div>
            </div>

            <div class="form-group form-check select" *ngIf="arr['type'] === 'select' && !arr['hidden']" [ngClass]="{'grid-item': gridLayout, 'disable-pointer-event': arr['disableEvent']}">
                <mat-form-field class="dynamic-form-text-fields">
                    <mat-label class="label">{{arr['label'] | translate}}
                        <span *ngIf="arr['required']" class="mandatory">*</span>
                    </mat-label>
                    <input *ngIf="arr['autocomplete']" type="text" matInput [formControlName]="arr['name']"
                    [placeholder]="(arr['placeholder']| translate ) + ' ' + (arr['label'] | translate)"  [matAutocomplete]="auto"
                    (input)="onInputChange($event, arr)" (focus)="onInputFocus(arr)"  
                    [value]="displayFn(arr, createForm.controls[arr['name']].value)" >
                <mat-autocomplete  #auto="matAutocomplete" (optionSelected)="onAutocompleteSelect($event, arr)"  [displayWith]="displayFn.bind(this, arr)" >
                    <mat-option *ngFor="let val of filteredOptions[arr['name']]" [value]="val.id">
                        {{val.name | translate}}
                    </mat-option>
                </mat-autocomplete>
                    <mat-select [tabIndex]="arr['disableEvent']?-1:0" [formControlName]="arr['name']" [placeholder]="arr['placeholder'] | translate"
                        class="dynamic-input" *ngIf="!arr['autocomplete'] && dropDownValues[arr['name']] && !arr['selectionType']"
                        [disabled]="arr['readOnly']"
                        (selectionChange)="(arr['childField'] && selectValue($event,arr))||(arr['isParent'] && selectNewField($event,arr))">
                        <mat-option>{{ 'SELECT' | translate }}</mat-option>
                        <mat-option *ngFor="let val of dropDownValues[arr['name']]" [value]="val.id">
                            {{val['name'] | translate}}</mat-option>
                    </mat-select>
                    <input type="text" *ngIf="!dropDownValues[arr['name']]" matInput [formControlName]="arr['name']"
                        [readonly]="!arr['editable']" [placeholder]="arr['placeholder'] | translate" />
                    <span matSuffix *ngIf="arr['unit']">{{arr['unit'] | translate}}</span>
                </mat-form-field>
                <div *ngIf="submitted || control[arr['name']].touched && control[arr['name']].errors">
                    <div *ngIf="createForm.controls[arr['name']]?.errors?.required">
                        <mat-error class="mat-error-class">
                            {{arr['label'] | translate}} {{'IS_REQUIRED' | translate }}
                        </mat-error>
                    </div>
                </div>
            </div>

            <div class="form-group form-check select" *ngIf="arr['type'] === 'select-multiple'" [ngClass]="gridLayout?'grid-item':''">
                <mat-form-field class="dynamic-form-text-fields">
                    <mat-label class="label">{{arr['label'] | translate}}
                        <span *ngIf="arr['required']" class="mandatory">*</span>
                    </mat-label>
                    <mat-select [formControlName]="arr['name']" class="dynamic-input"
                        [placeholder]="arr['placeholder'] | translate"
                        (selectionChange)="(arr['childField'] && selectValue($event,arr['childField']))||(arr['isParent'] && selectNewField($event,arr['isParent']))"
                        multiple>
                        <mat-option *ngFor="let val of dropDownValues[arr['name']]" [value]="val.id" required
                            [disabled]="val['isDisabled']" (click)="(arr['checkboxAsRadioButtonsGroup'] && handleSelected(val))">
                            {{val['name'] | translate}}</mat-option>
                    </mat-select>
                </mat-form-field>
                <p class="disclaimer" *ngIf="arr['disclaimer'] && (formData && formData['disclaimer'])">
                    {{arr['disclaimer'] | translate}}</p>
                <div *ngIf="submitted || control[arr['name']].touched && control[arr['name']].errors">
                    <div *ngIf="createForm.controls[arr['name']]?.errors?.required">
                        <mat-error class="mat-error-class">
                            {{'PLEASE_SELECT_ATLEAST_ONE' | translate }} {{arr['label']| translate}}
                        </mat-error>
                    </div>
                </div>
            </div>

            <div class="form-group file" *ngIf="arr['type'] === 'file' || arr['type'] === 'anchor' " [ngClass]="gridLayout?'grid-item':''">
                <div class="form-group file" *ngIf="arr['type'] === 'anchor' && (sampleFile || sampleFileDownloadApi) ">
                    <div>
                        <input type="file" #csvFileInputType accept=".csv" class="select-file" id="file"
                            (click)="csvFileInputType.value = null" (change)="selectFiles($event)" />
                        <label class="input-label" for="file">{{ 'UPLOAD_FILE' | translate }}</label>
                        <a *ngIf="arr['type'] === 'anchor' && sampleFile" [href]="sampleFile" download class="sample-file-link">
                            {{'DOWNLOAD_SAMPLE_FILE' | translate}}</a>
                            <a *ngIf="arr['type'] === 'anchor' && sampleFileDownloadApi" (click)="downloadFile($event)"
                            class="sample-file-link">{{'DOWNLOAD_SAMPLE_FILE' | translate }}</a>
                    </div>
                    <div *ngIf="submitted && !files.length">
                        <div *ngIf=" !files.length">
                            <mat-error class="mat-error-class">
                                {{ 'FILE' | translate}} {{'IS_REQUIRED' | translate }}
                            </mat-error>
                        </div>
                    </div>
                </div>
                <div *ngIf="arr['type'] === 'file'" [ngClass]="gridLayout?'grid-item':''">
                    <input type="file" class="select-file" id="file" (change)="selectFiles($event)" multiple
                        *ngIf="arr['multiple']" />
                    <input type="file" #inputCertificate class="select-file" id="file"
                        (click)="inputCertificate.value = null && convertTobase64($event, arr['multiple'])"
                        (change)="convertTobase64($event, arr['multiple'])" *ngIf="!arr['multiple']" />
                    <label class="input-label" for="file">{{ 'UPLOAD_FILE' | translate }}</label>
                </div>
                <div class="files-list">
                    <div class="single-file" *ngFor="let file of files; let indexNumber = index">
                        <div class="file-icon">
                            <img [src]="documentImage" />
                        </div>
                        <div class="info">
                            <h4 class="name"> {{ file?.name }} </h4>
                            <p class="size"> {{ formatBytes(file?.size) }} </p>
                        </div>
                        <div class="delete" (click)="removeFile(indexNumber)">
                            <img [src]="deleteImage" class="modify-button">
                        </div>
                    </div>
                </div>

                <div *ngIf="submitted">
                    <div *ngIf="createForm.controls[arr['name']]?.errors?.required">
                        <mat-error class="mat-error-class">
                            {{ arr['label'] | translate}} {{'IS_REQUIRED' | translate }}
                        </mat-error>
                    </div>
                </div>
            </div>

            <div class="form-group file" *ngIf="arr['type'] === 'drag-and-drop-multiple-file'" [ngClass]="gridLayout?'grid-item':''">
                <div class="upload-file-container" appDragAndDrop (fileDropped)="onFileDropped($event)">
                    <input class="input-file-btn" type="file" #fileDropRef id="fileDropRef" multiple
                        (change)="fileBrowseHandler($event); selectNewField($event, arr);" />
                    <h6>{{ 'DRAG_AND_DROP' | translate }}</h6>
                    <label class="input-label" for="fileDropRef">{{ 'SELECT_FILES' | translate }}</label>
                </div>
                <div class="files-list">
                    <div class="single-file" *ngFor="let file of files; let indexNumber = index">
                        <div class="file-icon">
                            <img [src]="documentImage" />
                        </div>
                        <div class="info">
                            <h4 class="name"> {{ file?.name }} </h4>
                            <p class="size"> {{ formatBytes(file?.size) }} </p>
                        </div>
                        <div class="delete" (click)="deleteFile(indexNumber, $event, arr)">
                            <img [src]="deleteImage" class="modify-button">
                        </div>
                    </div>
                </div>
                <div *ngIf="submitted">
                    <div *ngIf="createForm.controls[arr['name']]?.errors?.required">
                        <mat-error class="mat-error-class">
                            {{ 'FILE' | translate}} {{'IS_REQUIRED' | translate }}
                        </mat-error>
                    </div>
                </div>
            </div>

            <div *ngIf="arr['type'] === 'date'" [ngClass]="gridLayout?'grid-item':''">
                <mat-form-field class="dynamic-form-text-fields">
                    <mat-label class="label">{{arr['label'] | translate}}
                        <span *ngIf="arr['required']" class="mandatory">*</span>
                    </mat-label>
                    <input matInput [matDatepicker]="picker" [placeholder]="arr['placeholder'] | translate"
                        (dateChange)="checkDate(arr['name'],arr['child'],arr['validate'],arr['fromDateControlName'],arr['toDateControlName'])" formControlName="{{arr['name']}}"
                      class="dynamic-input"  [min]="arr['enableOlderDates']?null:todayDate" />
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker disabled="false">
                    </mat-datepicker>
                </mat-form-field>
                <div
                    *ngIf="submitted || createForm.controls[arr['name']].touched && createForm.controls[arr['name']].errors">
                    <div *ngIf="createForm.controls[arr['name']]?.errors?.required">
                        <mat-error class="mat-error-class">
                            {{arr['label'] | translate}} {{'IS_REQUIRED' | translate }}
                        </mat-error>
                    </div>
                    <div class="error-message" *ngIf="arr['validate'] && createForm.controls[arr['name']].errors?.invalidDate">
                        <mat-error >
                            {{'FROMDATE_LESSTHAN_TODATE' | translate }}</mat-error>
                    </div>
                </div>
            </div>

            <div [hidden]="arr['hidden']" *ngIf="arr['type'] === 'subHeader'" class="grid-item" [ngClass]="{'display-none': arr['displayNone']}">
                <mat-label class="label label-subHeader">{{ arr.label | translate }}
                </mat-label>
              </div>

              <div *ngIf="arr['type'] === 'image'" class="grid-item">
                <mat-label class="label label-subHeader">{{ arr.label | translate }}
                    <span *ngIf="arr['required']" class="mandatory">*</span>
                    <span class="download-icon" *ngIf="arr['downloadUrl']">
                        <img [src]="downloadIconSmall" alt="download" [title]="arr['tooltip'] | translate" (click)="downloadImageFile(arr['downloadUrl'])">
                    </span>
                </mat-label>
                <div class="file-uploader form-field"  (click)="imageInput.click()" [ngClass]="{'disable-pointer-event': arr['disableEvent']}">
                    <input type="file" [tabIndex]="arr['disableEvent']?-1:0" #imageInput  accept="image/png, image/jpeg, image/jpg" class="image-input image-file" (change)="validateImageFile($event,arr['name'],arr['maxSizeBytes'],arr['maxSizeMB'],arr['height'],arr['width'])" />
                    <div *ngIf="!imgSrc" class="file-upload-content">
                    <span class="heading-label">
                    <img class="page-icon-small" [src]=" cloudUploadImage" />
                    </span>
                  <p class="file-upload-message-size" >{{arr.maxSizeMessage | translate}}</p>
                  <p class="file-upload-message-dimension" >{{arr.dimensionsMessage | translate}}</p>
                  </div>
                  <div *ngIf="imgSrc" class="preview-img-container">
                    <img class="preview-img" [src]="imgSrc">
                  </div>
                </div>
              <div
              *ngIf="submitted || createForm.controls[arr['name']].touched && createForm.controls[arr['name']].errors" class="mat-profile-photo-error-class">
              <div *ngIf="createForm.controls[arr['name']]?.errors?.required">
                  <mat-error >
                      {{arr['label'] | translate}} {{'IS_REQUIRED' | translate }}
                  </mat-error>
              </div>
          </div>
              </div>
              

            <div *ngIf="arr['type'] === 'time'" [ngClass]="gridLayout?'grid-item':''">
                <mat-form-field class="dynamic-form-text-fields">
                    <mat-label class="label">{{arr['label'] | translate}}
                        <span *ngIf="arr['required']" class="mandatory">*</span>
                    </mat-label>
                    <span *ngIf="arr['infoIcon']" class="info-icons">
                        <img [src]="arr['infoIcon']" alt="info" [title]="arr['tooltip'] | translate" [ngClass]="arr['class']">
                    </span>
                    <input [type]="arr['type']" matInput [formControlName]="arr['name']"
                        class="dynamic-input monthPicker-readonly" (change)="timeValidator(arr['name'],arr['maxTime'])"
                        [placeholder]="arr['placeholder'] | translate " />
                </mat-form-field>

                <div
                    *ngIf="submitted || createForm.controls[arr['name']].touched && createForm.controls[arr['name']].errors">
                    <div *ngIf="createForm.controls[arr['name']]?.errors?.required">
                        <mat-error class="mat-error-class">
                            {{arr['label'] | translate}} {{'IS_REQUIRED' | translate }}
                        </mat-error>
                    </div>
                    <mat-error *ngIf="createForm.get(arr['name']).errors
                ?.incorrect" class="mat-error-class">
                        {{ arr["errorStartTimeMsg"] | translate }}
                    </mat-error>
                </div>
            </div>

            <div *ngIf="arr['type'] === 'arrayOfFields'" [formGroup]="createForm.get(arr['name'])" [ngClass]="gridLayout?'grid-item':''">
                <div [class.disabled]="!formData[arr['name']]">
                    <div class="array-title">{{arr['title'] | translate}}</div>
                    <div *ngFor="let field of arr['fields']">
                        <div *ngIf="field['type']==='number'" class="array-number-display">
                            <mat-label class="label">{{field['label'] | translate}}
                            </mat-label>
                            <input [readonly]="(field['disabled'])" type="number" [formControlName]="field['name']"
                                [min]="field['min']" [max]="field['max']" />
                            <div class="info-msg-color">{{'DEFAULT_VALUE' | translate}}{{field['defaultValue']}}</div>
                            <div
                                *ngIf="submitted || createForm.controls[(arr['name'])].controls[field['name']].touched && createForm.controls[(arr['name'])].controls[field['name']].errors">
                                <mat-error
                                    *ngIf="createForm.controls[(arr['name'])].controls[field['name']]?.errors?.min">
                                    {{field['errorMinMsg'] | translate}} {{field['min']}}</mat-error>
                                <mat-error
                                    *ngIf="createForm.controls[(arr['name'])].controls[field['name']]?.errors?.max">
                                    {{field['errorMaxMsg'] | translate}} {{field['max']}}</mat-error>
                            </div>
                        </div>

                        <div *ngIf="field['type']==='checkbox'" class="master-checkbox">
                            <mat-label class="label">{{field['label'] | translate}}
                            </mat-label>
                            <mat-checkbox class="checkbox-width" [formControlName]="field['name']"
                                (change)="(field['isDisableParent'] && changeDisableState($event, arr['fields'],arr['name']))"
                                [(ngModel)]="field['value']">
                            </mat-checkbox>
                        </div>
                    </div>
                </div>
            </div>

            <div [formArrayName]="arr['name']" *ngIf="arr['type'] === 'formArray'" [ngClass]="gridLayout?'grid-item':''">
                <div *ngFor="let group of createForm.get(arr['name']).controls; index as i">
                    <div [formGroupName]="i" class="form-array-fields" [ngClass]="[formArrayGridLayout ? formArrayGridLayout : '', arr['hidden']?'display-none':'']">
                        <div *ngFor="let field of arr['fields']"   [ngClass]="formArrayGridLayout?arr['class']:'row-fields' && arr['class'] ">
                            <div *ngIf="field['type']==='select' && !field['hidden']" [ngClass]="formArrayGridLayout?'grid-item':''">
                                <mat-form-field class="single-row-fields" [ngStyle]="field['styles']">
                                    <mat-label class="label">{{field['label'] | translate}}
                                        <span *ngIf="field['required']" class="mandatory">*</span>
                                    </mat-label>
                                    <mat-select [formControlName]="field['name']" [disabled]="field['readonly']"
                                        [placeholder]="field['placeholder'] | translate" class="dynamic-input"
                                        *ngIf="dropDownValues[field['name']]"
                                        (selectionChange)="(field['isParent'] && selectNewField($event,field)) || (field['childField'] && selectValue($event,field))">
                                        <mat-option>{{ 'SELECT' | translate }}</mat-option>
                                        <mat-option *ngFor="let val of dropDownValues[field['name']]" [value]="val.id"  [title]="val['name']">
                                            {{val['name'] | translate}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>

                            <div *ngIf="field['type'] === 'select-multiple'">
                                <mat-form-field class="single-row-fields">
                                    <mat-label class="label">{{field['label'] | translate}}
                                        <span *ngIf="field['required']" class="mandatory">*</span>
                                    </mat-label>
                                    <mat-select [formControlName]="field['name']" class="dynamic-input"
                                        [placeholder]="field['placeholder'] | translate" multiple>
                                        <mat-option *ngFor="let val of dropDownValues[field['name']]" [value]="val.id"
                                            required [disabled]="val['isDisabled']">
                                            {{val['name'] | translate}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>

                            <div *ngIf="field['type']==='text'" [ngClass]="formArrayGridLayout?'grid-item':field['class']">
                                <mat-form-field class="single-row-fields" [ngStyle]="field['styles']" [ngClass]="field['mat-form-class']">
                                    <mat-label class="label">{{field['label'] | translate}}
                                        <span *ngIf="field['required']" class="mandatory">*</span>
                                    </mat-label>
                                    <input [type]="field['type']" matInput [formControlName]="field['name']"
                                        [placeholder]="field['placeholder'] | translate" class="dynamic-input"
                                        [readonly]="field['editableOnAddOnly']?i<formArrayLength?field['readOnly']:false:field['readOnly']" />
                                </mat-form-field>
                            </div>

                            <div class="form-group" *ngIf="field['type'] === 'textArea'" [ngClass]="formArrayGridLayout?'grid-item':field['class']">
                                <mat-form-field floatLabel="always" class="dynamic-form-text-area">
                                    <mat-label class="label">{{field['label'] | translate}}
                                        <span *ngIf="field['required']" class="mandatory">*</span>
                                    </mat-label>
                                    <textarea class="form-control text-area dynamic-input" matInput
                                        [formControlName]="field['name']"
                                        [placeholder]="field['placeholder'] | translate" rows="4">
                                    </textarea>
                                </mat-form-field>
                            </div>

                            <div *ngIf="field['type']==='number'" [ngClass]="formArrayGridLayout?'grid-item':''">
                                <mat-form-field class="single-row-fields" [ngStyle]="field['styles']">
                                    <mat-label class="label">{{field['label'] | translate}}
                                        <span *ngIf="field['required']" class="mandatory">*</span>
                                    </mat-label>
                                    <input [type]="field['type']" matInput [formControlName]="field['name']"
                                        [placeholder]="field['placeholder'] | translate" class="dynamic-input"
                                        [readonly]="field['readonly']" (blur)="field['bluerEvent'] ? onInput($event.target.value) : null"/>
                                </mat-form-field>
                            </div>

                            <div *ngIf="field['type']==='checkbox'" class="master-checkbox">
                                <mat-label class="label">{{field['label'] | translate}}
                                </mat-label>
                                <mat-checkbox class="checkbox-width" [formControlName]="field['name']"
                                    [disabled]="field['disabled']" [(ngModel)]="field['value']">
                                </mat-checkbox>
                            </div>

                            <div *ngIf="field['type'] === 'monthPicker'">
                                <mat-form-field class="single-row-fields">
                                    <mat-label class="label">{{field['label'] | translate}}
                                        <span *ngIf="field['required']" class="mandatory">*</span>
                                    </mat-label>
                                    <input [formControlName]="field['name']" class="dynamic-input monthPicker-readonly"
                                        [placeholder]="'SELECT' | translate" [readonly]="field['readonly']" />
                                    <input matInput [matDatepicker]="picker" hidden disabled class="dynamic-input"
                                        [min]="todayDate">
                                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                    <mat-datepicker #picker [disabled]="disableField" startView="multi-year"
                                        (monthSelected)="chosenMonthHandler($event, picker, arr, field['name'])">
                                    </mat-datepicker>
                                </mat-form-field>
                            </div>

                            <div
                                *ngIf="submitted || group.controls[field['name']].touched && group.controls[field['name']].errors">
                                <div *ngIf="group.controls[field['name']]?.errors?.required">
                                    <mat-error class="mat-error-class">
                                        {{field['label'] | translate}} {{'IS_REQUIRED' | translate }}
                                    </mat-error>
                                </div>
                                <mat-error class="mat-error-class"
                                    *ngIf="group.controls[field['name']]?.errors?.maxlength">
                                    {{field['errorMaxLengthMsg'] | translate}}</mat-error>
                                <mat-error class="mat-error-class"
                                    *ngIf="group.controls[field['name']]?.errors?.minlength">
                                    {{field['errorMinLengthMsg'] | translate}}</mat-error>
                                <mat-error class="mat-error-class"
                                    *ngIf="group.controls[field['name']]?.errors?.pattern">
                                    {{field['errorPatternMsg'] | translate}}</mat-error>
                            </div>
                        </div>
                        <span *ngIf="!hideTableActionButtons">
                            <span *ngIf="arr['addButton']">
                                <button type="button" mat-raised-button color="primary" class="add-button"  [title]="'ADD' |translate"
                                (click)="arr['maxFormArrayLength']&&createForm.controls[arr['name']].controls.length >=arr['maxFormArrayLength']?showMaxLengthError(arr['errorMaxFormArrayLengthMsg']) :arr['table']? addToTable(arr, group):addFormGroup(arr)"
                                >{{'ADD' | translate }}</button>
                            </span>
                        </span>
                        <span  *ngIf="!arr['addButton']" [ngClass]="formArrayGridLayout?'grid-item':''">
                            <button type="button" mat-icon-button color="primary"
                                (click)="arr['maxFormArrayLength']&&createForm.controls[arr['name']].controls.length >=arr['maxFormArrayLength']?showMaxLengthError(arr['errorMaxFormArrayLengthMsg']) :arr['table']? addToTable(arr, group):addFormGroup(arr)"
                                [title]="'ADD' |translate"
                                class="action-buttons"><span class="action-button-font">+</span></button>
                            <button mat-icon-button color="primary"
                                *ngIf="createForm.controls[arr['name']].controls.length > 1"
                                [title]="'REMOVE' |translate" (click)="
                            removeFormGroup(i,arr)" class="action-buttons"><span
                                    class="action-button-font">-</span></button>
                        </span>
                    </div>
                </div>
                <div *ngIf="dataSource.length > 0 && arr['table']">
                    <mat-table [dataSource]="dataSource" class="table-data">
                        <div *ngFor="let column of columns">
                            <ng-container [matColumnDef]="column">
                                <mat-header-cell *matHeaderCellDef>{{transFormColumn(column)}}
                                </mat-header-cell>
                                <mat-cell class="tooltip " *matCellDef="let dataName; index as i">
                                    <span title="{{dataName[column]}}" class="cell-value">
                                        {{dataName[column]}}
                                    </span>
                                    <span *ngIf="column==='action' && !dataName['hideEditIcon']"> <img [src]="editImage"
                                            (click)="gotoEditConfirmDialog(arr, dataName, i)" class="modify-button">
                                    </span>
                                    <span *ngIf="column==='action'"> <img [src]="deleteImage"
                                            (click)="gotoDeleteConfirmDialog(i, arr)" class="modify-button">
                                    </span>
                                </mat-cell>
                            </ng-container>
                        </div>
                        <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
                        <mat-row *matRowDef="let data; columns: columns;"></mat-row>
                    </mat-table>
                </div>
            </div>

            <div *ngIf="arr['type']==='mat-chip-list'">
                <mat-form-field class="dynamic-form-text-fields">
                    <mat-label class="label">{{arr['label'] | translate}}</mat-label>
                    <mat-chip-list #chipList aria-label="">
                        <mat-chip *ngFor="let val of selectedValuesArray" class="mat-chip" [selectable]="selectable"
                            [removable]="removable" (removed)="removeEntities(val)" [value]="val">
                            {{val | translate }}
                            <mat-icon matChipRemove *ngIf="removable" class="rb-ic" fontIcon="bosch-ic-close">
                            </mat-icon>
                        </mat-chip>
                    </mat-chip-list>
                    <input class="mat-chip-input" [formControlName]="arr['name']"
                        [placeholder]="arr['placeholder'] | translate" #inputCtrl [matAutocomplete]="auto"
                        [matChipInputFor]="chipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                        (matChipInputTokenEnd)="addValues($event)" (keypress)="filterExistingList($event)">
                    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectEntitiesFromList($event)">
                        <mat-option *ngFor="let val of filteredStrings | async " [value]="val">
                            {{val | translate}}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </div>

            <div *ngIf="arr['selectionType']==='optGroup'">
                <mat-form-field class="dynamic-form-text-fields">
                    <mat-label class="label">{{arr['label'] | translate}}
                        <span *ngIf="arr['required']" class="mandatory">*</span>
                    </mat-label>
                    <mat-select [formControlName]="arr['name']" [placeholder]="arr['placeholder'] | translate"
                        class="dynamic-input" [disabled]="arr['readOnly']"
                        (selectionChange)="(arr['childField'] && selectValue($event,arr))||(arr['isParent'] && selectNewField($event,arr))">
                        <mat-optgroup class="" *ngFor="let group of dropDownValues[arr['name']]"
                            [label]="group.name | translate">
                            <mat-option *ngFor="let val of group.values" [value]="val.id">
                                {{val['name']}}
                            </mat-option>
                        </mat-optgroup>
                    </mat-select>
                </mat-form-field>
                <p class="disclaimer" *ngIf="validityMessage">{{validityMessage | translate}}</p>
                <div *ngIf="submitted || control[arr['name']].touched && control[arr['name']].errors">
                    <div *ngIf="createForm.controls[arr['name']]?.errors?.required">
                        <mat-error class="mat-error-class">
                            {{arr['label'] | translate}} {{'IS_REQUIRED' | translate }}
                        </mat-error>
                    </div>
                </div>
            </div>
        <section  class="section" *ngIf="arr['type'] === 'searchButton'">
                <div *ngIf="arr['type'] === 'searchButton'"  class="input-field-get" >
                    <button  (click)="onSubmit()" mat-raised-button class="button save-continue-button search-icon">{{'SEARCH' | translate }}</button>
                </div> 
        </section>
   </div>
    </form>
    <div>
        <section [ngClass]="{ 'display-none': hideSubmitButton}" class="section">
            <button (click)="onSubmit()" mat-raised-button class="button save-continue-button button-submit"
                [disabled]='disableField' #submitButton>{{ (saveAndContinue ? 'SAVE_AND_CONTINUE' : fileTransfer ?
                'UPLOAD' : 'SUBMIT') |
                translate }}</button>
            <button *ngIf="!saveAndContinue && !fileTransfer" (click)="onReset()" mat-button class="button">{{
                'RESET' | translate }}</button>
        </section>
    </div>
</div>